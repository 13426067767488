"use strict";

// tohle nahraje i soubory, ktere nejsou primo linkovane z entrypointu
import "./img/*.{png,jpg,jpeg,gif,svg}";

// import stylu
import "../index.scss";

// carousel
import "./carousel";

//import cookieconsent from "vanilla-cookieconsent";
//import "./cookieConsentSettings";

// SmoothScroll inicializace
import SmoothScroll from "smooth-scroll";
new SmoothScroll('a[href*="#"]', {
  header: "header",
  speed: 300,
  speedAsDuration: true,
});

// NodeList.prototype.forEach() polyfill
if (window.NodeList && !NodeList.prototype.forEach) {
  NodeList.prototype.forEach = Array.prototype.forEach;
}

// galerie SimpleLightbox - BEGIN
// https://simplelightbox.com
import SimpleLightbox from "simplelightbox";
import "simplelightbox/src/simple-lightbox.scss";
window.addEventListener("DOMContentLoaded", () => {
  document.querySelectorAll("*[data-gallery]").forEach((gallery) => {
    new SimpleLightbox("#" + gallery.getAttribute("id") + " a", {
      /* options */
    });
  });
});
// galerie SimpleLightbox - BEGIN

import GLightbox from "glightbox";
import "glightbox/dist/css/glightbox.min.css";
document.addEventListener("DOMContentLoaded", function () {
  //console.log('glitobox');
  const lightbox = GLightbox({
    touchNavigation: true,
    loop: true,
    autoplayVideos: true,
  });
});

// clickToggleClass - BEGIN
window.addEventListener("DOMContentLoaded", () => {
  document.querySelectorAll("*[data-click-toggle-class]").forEach((elm) => {
    elm.addEventListener("click", (event) => {
      event.preventDefault();
      const classes = event.target.dataset.clickToggleClass.split("|");
      const targets = event.target.dataset.clickToggleTarget
        ? [...document.querySelectorAll(event.target.dataset.clickToggleTarget)]
        : [event.target];
      const toggledClases = targets[0].classList.contains(classes[0])
        ? {
            remove: classes[0],
            add: classes[1],
          }
        : {
            remove: classes[1],
            add: classes[0],
          };
      targets.forEach((elm) => {
        elm.classList.remove(toggledClases.remove);
        elm.classList.add(toggledClases.add);
      });
    });
  });
});
// clickToggleClass - END
//modal fucntion
window.addEventListener("DOMContentLoaded", () => {
  const modal = document.getElementById("modal");
  if (typeof modal != "undefined" && modal != null) {
    // ZAVRENI MODALU
    const modalClose = document.getElementById("modalClose");
    modalClose.addEventListener("click", (event) => {
      event.preventDefault();
      modal.classList.add("modal__hidden");
    });

    //OTEVRENI MODALU
    const modalBody = document.getElementById("modalBody");

    document.querySelectorAll("*[data-open-modal]").forEach((elm) => {
      elm.addEventListener("click", (event) => {
        event.preventDefault();
        modal.classList.remove("modal__hidden");
        modalBody.scrollIntoView();
      });
    });
  }
});

//scroll na modal
window.addEventListener("DOMContentLoaded", () => {
  const modal = document.getElementById("modal");
  if (typeof modal != "undefined" && modal != null) {
    if (!modal.classList.contains("modal__hidden")) {
      document.getElementById("modalBody").scrollIntoView();
    }
  }
});

//check scroll position, and under zero add white background
/*
let scrolled = false;
const scrolledMenu = document.getElementById("topMenu");
document.addEventListener("scroll", function(){ // or window.addEventListener("scroll"....
  let st = window.pageYOffset || document.documentElement.scrollTop; // Credits: "https://github.com/qeremy/so/blob/master/so.dom.js#L426"
  if (st>5 && scrolled===false) {
    scrolled = true;
    scrolledMenu.classList.add("topMenu__scrolled");
  }

  if (st<=5 && scrolled===true) {
      scrolled = false;
      scrolledMenu.classList.remove("topMenu__scrolled");
  }
}, false);
*/
//SCROLL FUNCTIONS
var scrl = 0;
var scrlA = 0;
var scrlB = 0;
var scrlMax = 450;
var scrlState = 0;

//scrlState - 0 - menu je vidět a je bez pozadí
//scrlState - 1 - menu je vidět a je tam pozadí
//scrlState - 2 - menu není vidět a je tam pozadí

$(window).scroll(function (event) {
  scrlA = $(window).scrollTop();
  //console.log("scrlA " + scrlA);
  if (scrlB == scrlA && scrlA == 0) {
    //console.log("nehejbe se a je nahoře");
    //menu je vidět a je bez pozadí
    if (scrlState != 0) {
      $("#topMenu").removeClass("topMenu__scrolled");
      $("#topMenu").removeClass("topMenu__topHidden");
      scrlState = 0;
    }
  } else if (scrlB > scrlA && scrlA > 0) {
    //console.log("jede nahoru  a není nahoře");
    //menu je vidět a je tam pozadí
    if (scrlState != 1) {
      $("#topMenu").addClass("topMenu__scrolled");
      $("#topMenu").removeClass("topMenu__topHidden");
      scrlState = 1;
    }
  } else if (scrlB < scrlA && scrlA >= scrlMax) {
    //console.log("jede dolu a není nahoře a je výš než max");
    //menu není vidět
    if (scrlState != 2) {
      $("#topMenu").addClass("topMenu__scrolled");
      $("#topMenu").addClass("topMenu__topHidden");
      scrlState = 2;
    }
  } else if (scrlB < scrlA && scrlA < scrlMax) {
    //console.log("jede dolu a není nahoře a je níž než max");
    //menu je vidět a je tam pozadí
    if (scrlState != 1) {
      $("#topMenu").addClass("topMenu__scrolled");
      $("#topMenu").removeClass("topMenu__topHidden");
      scrlState = 1;
    }
  } else if (scrlA == 0) {
    //console.log("je nahoře");
    //menu je vidět a je bez pozadí
    if (scrlState != 0) {
      $("#topMenu").removeClass("topMenu__scrolled");
      $("#topMenu").removeClass("topMenu__topHidden");
      scrlState = 0;
    }
  }
  scrlB = $(window).scrollTop();
  /*
  if (scrlA == 0 && scrl != 0) {
    $(".headerBack").removeClass("down");
    $(".header").addClass("headerHide");
    scrl = 0;
    //console.log('vypnuto off');
  }
  if (scrlA > 0 && scrl == 0) {
    $(".headerBack").addClass("down");
    scrl = 1;
    //console.log('zapnuto off');
  }
  */

  //console.log($(window).scrollTop());
  // Do something
});

/* MODALNI OKNO */

/* MODALni oKNO CHARITA */

// Funkce pro získání hodnoty cookie podle jejího názvu
function getCookie(name) {
  let match = document.cookie.match(new RegExp("(^| )" + name + "=([^;]+)"));
  if (match) {
    return match[2];
  } else {
    return null;
  }
}

// Funkce pro zobrazení modálního okna
function showModal() {
  const modal = document.getElementById("modal");
  modal.style.display = "flex";

  // Uložení informace do cookie
  document.cookie = "modalOpened=true; path=/;";
}

// Zavření modálního okna
function closeModal() {
  const modal = document.getElementById("modal");
  modal.style.display = "none";
}

document.addEventListener("DOMContentLoaded", function () {
  // Zkontrolujeme, zda byla již modální okno otevřeno
  if (!getCookie("modalOpened")) {
    // Zobrazí modální okno po 2 vteřinách
    setTimeout(showModal, 2000);
  }

  // Získání prvků pro zavření modálního okna
  const closeBtn = document.querySelector(".close");
  const closeModalBtn = document.querySelector(".close-btn");

  // Zavření modálního okna při kliknutí na tlačítko zavřít
  closeBtn.addEventListener("click", closeModal);
  closeModalBtn.addEventListener("click", closeModal);

  // Zavření modálního okna při kliknutí mimo obsah okna
  window.addEventListener("click", function (event) {
    const modal = document.getElementById("modal");
    if (event.target === modal) {
      closeModal();
    }
  });
});
